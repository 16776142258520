import React from "react"
import PropTypes from "prop-types"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import Img from "gatsby-image"
import { Container, Row, Col } from "../../components/UI/wrapper"
import AccordionWrap from "../../components/UI/accordion"
import { useStaticQuery, graphql } from "gatsby"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { SectionWrap } from "./style"

const Preguntas = ({ title, description, accordionStyle }) => {
  const Data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "faq" } }) {
        edges {
          node {
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)
  const fotos = Data.allFile.edges
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Heading as="h5" content="Almacenamiento de los neumáticos" />
            <Text content="Se tienen que almacenar en un lugar fresco, seco y ventilado:" />
            <Text
              content={
                <ul>
                  <li>No expuestos a la luz directa del sol,</li>
                  <li>
                    Lejos de una fuente de ozono (motor eléctrico,
                    transformador, puesto de soldadura eléctrico)
                  </li>
                  <li>
                    Lejos de toda sustancia química, disolventes o hidrocarburos
                    susceptibles de alterar la naturaleza del caucho.
                  </li>
                  <li>
                    Lejos de todo objeto metálicos puntiagudos o cortantes.
                  </li>
                </ul>
              }
            />
            <Text
              content="Durante los periodos de almacenamiento, los neumáticos y las cámaras de aire deben conservarse de manera que no sufran deformaciones debidas a la tensión o al aplastamiento.

"
            />
            <Heading as="h5" content="Uso correcto  de los neumáticos" />
            <Text
              content={
                <ol>
                  <li>
                    Los neumáticos Off-Highway (Fuera de Carretera) se diseñan
                    para máquinas agrícolas, forestales, industriales,
                    construcción y minería, cuyo manejo inadecuado y
                    mantenimiento pobre pueden dañar estos neumáticos costosos y
                    por consiguiente pueden levantar substancialmente el costo
                    de operación de su equipo.
                  </li>
                  <li>
                    Así mismo para obtener el máximo rendimiento de sus
                    neumáticos Off-Highway es esencial adoptar hábitos de
                    conducir apropiadamente. Evitando agujeros/baches, hacer
                    giros bruscos y dar vueltas a velocidad alta. Todos ésto
                    pueden dañar los neumáticos.
                  </li>
                  <li>
                    Es importante respetar las presiones mínimas indicadas en
                    las fichas de producto del neumático.
                  </li>
                </ol>
              }
            />
            <Text
              content={
                <ul>
                  <li>
                    Una presión baja, causa una deformación exagerada de la
                    carcasa e implica un deterioro prematuro del neumático.
                  </li>
                  <li>
                    Una presión alta, disminuye la superficie de contacto con el
                    suelo con la llanta, esto implica una pérdida de adherencia
                    y hace que el neumático sea más sensible a los choques y a
                    los cortes.
                  </li>
                </ul>
              }
            />
            <Heading as="h5" content="Manipulación  de los neumáticos" />
            <Text content="Con el fin de reducir al mínimo el riesgo de daños o problemas debidos a un transporte o una manipulación incorrecta, se aconseja respetar todo lo siguiente:" />
            <Text
              content={
                <ul>
                  <li>
                    No levante el neumático con un gancho de grúa haciendo
                    palanca en el talón.
                  </li>
                  <li>
                    Utilice correas con fibras muy anchas, eslingas de goma o
                    específicos cinturones.
                  </li>
                  <li>No utilice cuerdas, cables de acero o cadenas.</li>
                  <li>
                    Utilice una carretilla elevadora. Levante el neumático por
                    debajo de la banda de rodamiento y no por el talón.
                  </li>
                </ul>
              }
            />
            <Img fixed={fotos[0].node.childImageSharp.fixed} />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <AccordionWrap {...accordionStyle}>
              <Accordion allowZeroExpanded preExpanded={[0]}>
                <AccordionItem id="1" key="1">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Heading {...title} content="¿Cómo leer un neumático?" />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Text
                      {...description}
                      content="Todos los neumáticos se abastecen con sus datos específicos, es importante saber cómo leerlos,  aquí  te ayudamos a reconocer el ancho, altura, tamaño de aro,  carga máxima  y rango de velocidad  que según la medida de tu neumático corresponde:"
                    />
                    <Img fixed={fotos[1].node.childImageSharp.fixed} />
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem id="2" key="2">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Heading
                        {...title}
                        content="Para conocer cuál es la carga y velocidad de tu neumático, te invitamos a revisar las siguientes tablas"
                      />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Text
                      {...description}
                      content="Índice de Carga: Es un código alfanumérico que especifica la capacidad máxima de carga del neumático en kg. Para cada tipo/medida de neumático, la capacidad de carga se refiere siempre a un valor específico de inflado definido en los estándares ETRTO y en las especificaciones técnicas del fabricante.

                    Código de Velocidad: Es un símbolo que indica la velocidad máxima permitida para un índice de carga determinado, teniendo en cuenta las otras condiciones específicas definidas por el fabricante."
                    />
                    <Img fixed={fotos[2].node.childImageSharp.fixed} />
                    <Img fixed={fotos[3].node.childImageSharp.fixed} />
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem id="3" key="3">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Heading
                        {...title}
                        content="¿Cómo se calcula el costo por servicio de Prensado?
"
                      />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Text
                      {...description}
                      content="
                    Para el caso de sólo servicio el costo se calcula sobre el ancho de sección, consulta con tu asesor. En Seggasa realizamos el servicio de prensado totalmente gratuito por la adquisición de tus llantas.

                    Te ofrecemos el servicio de prensado de llantas hasta en medida 1000 x 20 (a excepción de  aros artilleros).
                    Prensamos llantas sólidas y neumáticas sólo de MONTACARGA"
                    />
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem id="4" key="4">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Heading
                        {...title}
                        content="	¿Como debo solicitar el servicio de Prensado gratuito?
"
                      />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Text
                      {...description}
                      content="Para solicitar el servicio debes de seguir los siguientes pasos:

                    Una vez realizado el pedido coordina con tu asesor la fecha en que realizarías el prensado.
                    El servicio de prensado se realiza en nuestro taller ubicado en Av. José Leal 442 distrito Lince, una vez culminado el servicio nosotros nos encargamos de enviar las llantas a la planta que nos indiques."
                    />
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem id="5" key="5">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Heading
                        {...title}
                        content="¿Es fácil desmontar y montar una llanta? ¿Puedo hacerlo yo mismo?
"
                      />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Text
                      {...description}
                      content="
                    Para el caso de los neumáticos Off-Highway (Fuera de Carretera), por el tipo de trabajo que realizan los equipos en su mayoría operaciones peligrosas, tienen que ser realizadas exclusivamente por personal experimentado y calificado que utilice las adecuadas herramientas y que respete los procedimientos e instrucciones apropiados para una mayor seguridad. Un montaje incorrecto del neumático sobre el aro o rin puede causar la explosión del neumático, con el riesgo de producir heridas corporales graves e incluso mortales."
                    />
                    <Img fixed={fotos[4].node.childImageSharp.fixed} />
                    <Img fixed={fotos[5].node.childImageSharp.fixed} />
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem id="6" key="6">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Heading
                        {...title}
                        content="	¿A que se denomina lastrado de neumáticos?
"
                      />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Text
                      {...description}
                      content="En algunos casos particulares, para aumentar la adherencia o reducir el centro de gravedad de la máquina agrícola, tanto en Tubeless como en Tube Type, el lastrado de los neumáticos agrícola con líquido es una solución. El lastrado con líquido puede ser útil para obtener un aumento de la tracción y de la adherencia al terreno así como una mejor estabilidad (rigidez lateral).
                    Cuando se llena el neumático con el líquido, se aconseja no superar el 75% de su volumen con el fin de dejar una reserva de aire a por los menos del 25% del volumen del neumático.

"
                    />
                    <Img fixed={fotos[6].node.childImageSharp.fixed} />
                    <Text
                      {...description}
                      content="
                    En zonas donde operen con un clima rígido, se aconseja añadir al agua  productos anticongelantes como el cloruro de calcio o cloruro de magnesio, en cualquiera de los casos es necesario estas operaciones tienen que ser realizadas por profesionales especializados.

                    Con la válvula colocada en la parte superior, llenar con líquido la cámara de aire o el neumático Tubeless, hasta la altura de la válvula  al mismo tiempo que se saca el aire (esquema 2)."
                    />
                    <Img fluid={fotos[7].node.childImageSharp.fluid} />
                    <Text
                      {...description}
                      content="El inflado y la presión se ajustan con aire.

El volumen de aire que garantiza la presión es escaso (alrededor del 25% del volumen). La revisión regular de la presión es importante por lo que es  recomendable sea una frecuencia mensual."
                    />
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem id="7" key="7">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Heading
                        {...title}
                        content="	¿Por qué es conveniente rotar las ruedas?
"
                      />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Text
                      {...description}
                      content="
                    Ya que los neumáticos delanteros de los vehículos se desgastan más rápidamente que los traseros en general, su rotación suele aportar un desgaste más uniforme y permite aprovechar al máximo la vida útil de las bandas de rodadura.
                    Se recomienda rotar los neumáticos cada 10.000 km aproximadamente. No obstante, existen otros posibles factores que exigen una mayor rotación:
                    Conducir a una velocidad alta, cargas pesadas y distancias largas:si suele conducir a altas velocidades, transportar cargas pesadas o recorrer distancias largas, la tensión adicional a la que se ven sometidos los neumáticos podría llevar a un ligero aumento de la frecuencia de las rotaciones.
                    Desgaste desigual:los neumáticos deben rotarse lo antes posible si se detecta un desgaste desigual.
                    Zumbidos:si los neumáticos emiten un zumbido al circular por una carretera lisa, podría ser el momento de pensar en rotarlos."
                    />
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem id="8" key="8">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Heading
                        {...title}
                        content="¿Qué puede ayudar a cuidar mejor de mis llantas?
"
                      />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Text
                      {...description}
                      content="Mantener la presión de inflado a nivel recomendado, la presión correcta reduce el riesgo de perder el control del vehículo, también protege a los neumaticos de un desgaste prematuro y de daños reversibles en la construcción interna.
                    Comprobar regularmente la profundidad de la banda de rodadura de la los neumáticos y cámbielos cuando estén gastados.
                    Revisar la alineación y balanceo de sus llantas, esto permitirá reducir el consumo de combustible y evitará un desgaste disparejo de los neumáticos.
                    Verificar que las válvulas de sus llantas tengan los tapones para evitar fugas de aire y/o introducción de materiales extraños y dañinos para el neumático.
                    Evite los arrancones y frenados violentos, realizarlos en exceso puede reducir hasta un 35% la durabilidad de la llanta.
                    Evite chocar contra el sardinel o superficies similares, ya que esto puede causar cortes, separaciones internas o externas de la llanta.
                    Reduzca la velocidad en caminos mal pavimentados ya que la fuerza de impacto de las llantas con las piedras o baches es más severa con la velocidad.
                    Rotar las llantas cada 10,000 Km, esto aumentará la vida útil de sus neumáticos."
                    />
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem id="9" key="9">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Heading
                        {...title}
                        content="¿Qué tiempo deben durar mis llantas de equipo original?
"
                      />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Text
                      {...description}
                      content="Hay muchos factores que pueden afectar la duración de tus llantas, como:

                    Superficie del terreno,
                    Componentes de la banda de rodamiento,
                    Aplicación del vehículo
                    Mantenimiento de las llantas
                    Condiciones geográficas y atmosféricas,
                    Hábitos de manejo.
                    No resulta práctico predecir con exactitud la vida útil de ningún tipo de llanta en forma cronológica, ya que las condiciones de servicio varían considerablemente. Por lo tanto, es fundamental llevar a inspeccionar las llantas con regularidad (una vez al mes como mínimo)."
                    />
                    >
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem id="10" key="10">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <Heading
                        {...title}
                        content="¿Debo reemplazar mis llantas actuales con llantas del mismo tamaño?
"
                      />
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Text
                      {...description}
                      content="Nunca escoja un tamaño más pequeño que el que vino con el equipo. Las llantas siempre se deben reemplazar con la misma designación de tamaño.

                    Siempre debe evitarse la mezcla de neumáticos radiales y convencionales  en el mismo eje, puede llevar a la inestabilidad y a causar daños mecánicos."
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </AccordionWrap>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

Preguntas.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
}

Preguntas.defaultProps = {
  title: {
    fontSize: ["16px", "19px"],
    fontWeight: "400",
    color: "headingColor",
    letterSpacing: "-0.025em",
    mb: 0,
  },
  description: {
    fontSize: "15px",
    color: "textColor",
    lineHeight: "1.75",
    mb: 0,
  },
}

export default Preguntas
