import React from "react"
import SEO from "../components/seo"
import Preguntas from "../containers/preguntas"
import Section, { Row, Col } from "../components/UI/wrapper"
import Heading from "../components/Heading"
import PropTypes from "prop-types"

const SeccionPreguntas = ({ sectionStyle, titleStyle }) => (
  <>
    <SEO title="Preguntas Seggasa" />
    <main className="site-wrapper-reveal">
      <Section {...sectionStyle}>
        <Row>
          <Col lg={8} ml="auto" mr="auto">
            <Heading {...titleStyle} content="Preguntas y Recomendaciones" />
            <Preguntas />
          </Col>
        </Row>
      </Section>
    </main>
  </>
)

SeccionPreguntas.propTypes = {
  sectionStyle: PropTypes.object,
}

SeccionPreguntas.defaultProps = {
  sectionStyle: {
    pt: "120px",
    pb: "120px",
    responsive: {
      large: {
        pt: "100px",
        pb: "100px",
      },
      medium: {
        pt: "80px",
        pb: "80px",
      },
      small: {
        pt: "60px",
        pb: "60px",
        fullwidth: true,
      },
    },
  },
  titleStyle: {
    as: "h1",
    fontSize: "40px",
    textalign: "center",
    mb: "15px",
    wordwrap: "break-word",
    responsive: {
      medium: {
        fontSize: "35px",
      },
      small: {
        fontSize: "25px",
      },
    },
  },
}

export default SeccionPreguntas
